<template>
  <v-navigation-drawer
    v-if="allNavPages"
    :value="show"
    style="border: 1px groove #aaaaaa"
    :mini-variant="collapsed"
    mini-variant-width="70"
    width="215"
    :height="windowHeight"
    :permanent="$vuetify.breakpoint.lgAndUp"
    :temporary="!$vuetify.breakpoint.lgAndUp"
    :absolute="!$vuetify.breakpoint.lgAndUp"
    left>
    <!-- Logo -->
    <v-list-item @click="goHome()" class="d-flex justify-start">
      <v-list-item-icon class="pl-0 ml-0">
        <v-img
          :src="require('@/assets/images/starfish.png')"
          max-height="40"
          max-width="40"></v-img>
      </v-list-item-icon>
      <v-list-item-title> Starfish </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <!-- Navigation Pane Toggle -->
    <v-list-item @click="toggleCollapsed()" class="d-flex justify-start">
      <v-list-item-icon class="pl-0 ml-0">
        <v-icon v-if="collapsed" class="ml-2" color="primary">mdi-chevron-double-right</v-icon>
        <v-icon v-else color="primary">mdi-chevron-double-left</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <h4 class="primary--text">Navigation Bar</h4>
      </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <!-- Pages -->
    <div v-for="(appNavPages, app) in allNavPages" :key="app">
      <v-list nav dense v-for="(pages, header) in appNavPages.pages" :key="header">
        <h5 class="d-flex justify-start px-1">{{ header }}</h5>
        <v-list-item
          v-for="page in pages"
          :key="page.displayName"
          @click="navigate(app, page.displayName)"
          class="pr-0 mr-0 d-flex justify-start"
          :class="[$vuetify.breakpoint.lgAndDown ? 'my-0' : '']">
          <v-list-item-icon>
            <v-badge overlap :content="page.notifications" :value="page.notifications > 0">
              <v-icon :class="activeClass(page.displayName)">{{ page.icon }}</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-title :class="activeClass(page.displayName)">
            {{ page.displayName }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as functions from "@/javascript/functions"
import * as router from "@/javascript/router"

export default {
  name: "Navigation",
  components: {},
  props: ["app", "page", "show"],
  data() {
    return {
      allNavPages: {},
      collapsed: false,
    }
  },
  computed: {
    // appNavPages() {
    //   if (this.app) return this.allNavPages[functions.nameToSlug(this.app)] || {}
    //   else return {}
    // },
    user: function () {
      return this.$store.state.user
    },
    windowHeight() {
      return window.innerHeight
    },
  },

  methods: {
    activeClass(pageName) {
      if (functions.nameToSlug(this.page) == functions.nameToSlug(pageName)) return "primary--text"
    },
    clearNavPages() {
      this.allNavPages = {}
    },
    navigate(app, pageName) {
      let pageSlug = functions.nameToSlug(pageName)
      let appSlug = functions.nameToSlug(app)

      this.$router.push({
        name: "App Page",
        params: { app_slug: appSlug, page_slug: pageSlug },
      })

      this.$emit("pageInHeaderDisplay", pageName)
    },
    forceUpdate() {
      this.$forceUpdate()
    },
    loadNavPages() {
      this.allNavPages = router.getAuthorizedPages(this.user)
    },
    goHome() {
      this.$router.push("/")
    },
    toggleCollapsed() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.collapsed = !this.collapsed
        this.$store.state.navCollapsed = this.collapsed
      } else {
        this.$emit("closeNavBar")
      }
    },
  },
  mounted: function () {},
}
</script>
