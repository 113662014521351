const Permissions = {}
const NotificationType = {}

const LanguageMap = {
    "English": "en",
    "Japanese": "ja",
    "Chinese": "zh-CN",
    "Turkish": "tr",
    "Spanish": "es",
}

export {
    Permissions,
    NotificationType,
    LanguageMap
}
