<template>
  <v-btn color="good" outlined v-bind="$attrs" v-on="$listeners"> <slot></slot></v-btn>
</template>

<script>
export default {
  name: "core-btn-positive",
  props: {},
  components: {},
}
</script>
