<template>
  <v-btn color="neutral" outlined text v-bind="$attrs" v-on="$listeners"> <slot></slot></v-btn>
</template>

<script>
export default {
  name: "core-btn-neutral",
  props: {},
  components: {},
}
</script>
