<template>
  <component :is="component" v-if="component" />
</template>

<script>
import * as functions from "../../javascript/functions"

export default {
  props: ["app_slug"],
  data: function () {
    return {
      component: null,
    }
  },
  computed: {
    loader() {
      if (!this.app_slug) {
        return null
      }
      let folder = functions.slugToName(this.app_slug)

      return () => import(`../${folder}/${this.app_slug}`)
    },
  },
  mounted() {
    this.component = () => this.loader()
  },
}
</script>
