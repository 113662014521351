<template>
  <v-chip
    small
    :class="chipClass"
    :color="chipColor"
    v-bind="$attrs"
    v-on="$listeners"
    @click="copyToClipboard(user)">
    <v-icon class="mr-1" small>mdi-account-circle</v-icon>
    <span v-if="user" :class="[$vuetify.breakpoint.lgAndDown ? 'smallText' : '']">
      {{ user.full_name }}
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "core-user-chip",
  props: {
    userId: Number,
    secondary: Boolean,
  },
  data: function () {
    return {}
  },
  computed: {
    chipColor() {
      return this.secondary ? "secondary" : ""
    },
    chipClass() {
      return this.secondary ? "white--text" : ""
    },
    user() {
      return this.$store.state.allUsers.find((user) => {
        return user.id == this.userId
      })
    },
  },
  methods: {
    copyToClipboard(user) {
      navigator.clipboard.writeText(user.netid)
    },
  },
  watch: {},
  mounted: function () {},
}
</script>
