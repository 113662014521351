<!-- Text field for handling numbers and money

Props:
- value: v-model binding
- money: Enables '$' prefix and conversions to 2 point decimals
- showCounter: Enables counter buttons in the input
- showAddMenu: Enables an add button that pops up a dialog for making quick additions to the field
-->

<template>
  <core-text-field
    v-model="display"
    :type="showCounter ? 'number' : 'string'"
    :prefix="display && money ? '$' : ''"
    min="0"
    v-bind="$attrs"
    v-on="$listeners"
    v-on:keypress="isDecimal($event)"
    @change="change($event)"
    @click:append-outer="addMenuOpen()">
    <template v-slot:append><slot name="append"></slot></template>
    <template v-slot:append-outer>
      <slot name="append-outer">
        <!-- Pop up addition menu -->
        <v-menu
          v-if="showAddMenu"
          v-model="addMenu"
          :close-on-content-click="false"
          :nudge-top="20"
          :nudge-right="5"
          max-width="240"
          offset-x
          @input="addMenuOpen($event)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="secondary" v-bind="attrs" v-on="on">mdi-plus</v-icon>
          </template>
          <v-card class="px-4 py-2 overflow-hidden">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Amount"
                  ref="addField"
                  v-model="addAmount"
                  filled
                  outlined
                  dense
                  hide-details
                  v-on:keypress="isDecimal($event)"
                  @keydown="addKeyPressed($event)"></v-text-field>
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <core-btn-primary small inverted @click="addSum()">
                  Add<v-icon right class="ml-2 pr-1">mdi-plus</v-icon>
                </core-btn-primary>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </slot>
    </template>
  </core-text-field>
</template>

<script>
import * as functions from "@/javascript/functions"

export default {
  name: "core-number-field",
  props: {
    value: {},
    money: Boolean,
    showCounter: Boolean,
    showAddMenu: Boolean,
    allowEmpty: Boolean,
  },
  components: {},
  data() {
    return {
      display: 0,
      addMenu: false,
      addAmount: "",
    }
  },
  computed: {},
  methods: {
    addMenuOpen(isOpening) {
      this.addMenu = isOpening

      if (isOpening) {
        // The time finangling is necessary to give time for the popup to fully register
        this.$nextTick(() => {
          setTimeout(() => {
            // Auto-focus on open
            this.$refs.addField.$refs.input.focus()
          }, 100)
        })
      } else {
        // Reset input on close
        this.addAmount = ""
      }
    },
    addKeyPressed(input) {
      // Add sum on 'Enter'
      if (input.key == "Enter") {
        this.addSum()
      }
    },
    addSum() {
      let sum = parseFloat(this.display) + parseFloat(this.addAmount)
      this.change(sum)
      // Auto-close menu
      this.addMenuOpen(false)
    },
    isDecimal(input) {
      return functions.decimalNumber(input)
    },
    change(input) {
      let num = input
      if (!input) num = 0 // Default to 0 if null
      if (typeof num === "string") num = num.replace(/,/g, "") // Strip commas

      num = parseFloat(num)

      if (!input && this.allowEmpty) this.display = ""
      else this.display = num

      if (this.money) {
        // Format money fields. Inserts commas and 2 decimal places.
        const moneyOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const moneyFormat = new Intl.NumberFormat("en-US", moneyOptions)
        this.display = moneyFormat.format(num)
      }

      this.$emit("input", num)
    },
  },
  mounted() {
    // Initialize value
    this.change(this.value)
  },
  watch: {
    value: {
      handler(val) {
        // We need this to make sure we're catching if something changes in the parent.
        // Equality check to prevent infinite loop!
        if (val != this.display) {
          this.change(val)
        }
      },
    },
  },
}
</script>
