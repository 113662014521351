<template>
  <v-container fluid class="pa-2">
    <!-- User Settings -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-toolbar class="secondary white--text">
          <v-spacer></v-spacer>
          <v-toolbar-title>User Settings</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="my-4 justify-center">
      <v-col cols="9">
        <v-expansion-panels v-model="panelOpen">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2 class="primary--text">Notifications</h2>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="mt-4">
              <span>You will receive Notifications in CAPs and/or via Email when:</span>
              <v-row
                v-for="(notifications, role, index) in userNotifications"
                :key="role"
                class="d-flex justify-start my-5 mx-4">
                <h4 class="">{{ role }}</h4>
                <v-list-item
                  v-for="notification in notifications"
                  :key="notification.model"
                  class="">
                  <v-list-item-title>{{ notification.text }}</v-list-item-title>
                  <v-checkbox
                    label="CAPs"
                    v-model="notification.appNotification"
                    @click="updateUserNotifications(notification)">
                  </v-checkbox>
                  <v-checkbox
                    label="Email"
                    v-model="notification.emailNotification"
                    class="mx-6"
                    @click="updateUserNotifications(notification)">
                  </v-checkbox>
                  <v-progress-circular
                    v-if="notification.userSettingsUpdating"
                    size="20"
                    :width="2"
                    indeterminate
                    color="primary"></v-progress-circular>
                </v-list-item>
                <v-divider
                  v-if="index < Object.keys(userNotifications).length - 1"
                  class="mx-12 mt-2"
                  style="background-color: #ab0520"></v-divider>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row> </v-row>
  </v-container>
</template>

<script>
import { NotificationType, Permissions } from "@/javascript/constants"

export default {
  name: "user_settings",

  data: function () {
    return {
      panelOpen: 0,
      userNotifications: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    updateUserNotifications(notification) {
      notification.userSettingsUpdating = true

      let userAppNotifications = []
      let userEmailNotifications = []

      for (const role in NotificationType) {
        NotificationType[role].forEach((notification) => {
          if (notification.appNotification) {
            userAppNotifications.push(notification.model)
          }

          if (notification.emailNotification) {
            userEmailNotifications.push(notification.model)
          }
        })
      }

      let payload = {
        app_notifications: userAppNotifications,
        email_notifications: userEmailNotifications,
      }

      this.$django_api.put("core/user/", payload).then((response) => {
        this.$store.commit("app_notifications", userAppNotifications)
        this.$store.commit("email_notifications", userEmailNotifications)
      })

      setTimeout(() => {
        notification.userSettingsUpdating = false
      }, 1000)
    },
  },
  mounted: async function () {
    for (const role in NotificationType) {
      NotificationType[role].forEach((notification) => {
        if (this.user.app_notifications.includes(notification.model)) {
          notification.appNotification = true
        } else {
          notification.appNotification = false
        }

        if (this.user.email_notifications.includes(notification.model)) {
          notification.emailNotification = true
        } else {
          notification.emailNotification = false
        }
      })
    }

    this.userNotifications = NotificationType
  },
}
</script>
