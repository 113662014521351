<template>
  <v-btn color="secondary" :outlined="inverted" v-bind="$attrs" v-on="$listeners">
    <slot></slot
  ></v-btn>
</template>

<script>
export default {
  name: "core-btn-secondary",
  props: {
    inverted: Boolean,
  },
  components: {},
}
</script>
