<template>
  <base-error :error="error" type="Unauthorized"></base-error>
</template>

<script>
import base_error from "./base_error"

export default {
  components: {
    "base-error": base_error,
  },
  data: function () {
    return {
      dialog: false,
    }
  },
  props: { error: Object },
}
</script>
