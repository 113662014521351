import django_api from './django_api'
import store from './store'
import * as env from './env_settings'

let domain = "http://localhost:8000"
if(env.ENV_SETTINGS === "prod") {
    domain = "https://starfish.michaeluebele.net"
}

let isLoggingIn = false

function login() {
    if (isLoggingIn) {
        return }

    window.open(`${domain}/api/accounts/google/login/`)

    // isLoggingIn = true

        // Request microsoft authorization url.
    // django_api.get("core/login/").then(
    // (response) => {
    //     // Save authentication data when login window posts response data.
    //     window.addEventListener('message', login_handler)

    //     // Open the login window using the retrieved url.
    //     window.open(response.data.microsoft_authorization_url)

    //     // Prevent any additional login requests for 5 seconds. Prevents rare bug where many tabs can suddenly open.
    //     setTimeout(() => {
    //         isLoggingIn = false
    //     }, 5000)
    // })
}

// Handles the postMessage from the login window to store the auth token.
function login_handler(event) {
    isLoggingIn = false

    let origin = event.origin
    let expected = (django_api.defaults.baseURL).replace("/api/", "")
    if (origin != expected) {
        return
    }

    let auth_data = event.data
    // Ensure expected payload was returned from the microsoft authentication.
    if ("microsoft_auth" in auth_data) {
        auth_data = auth_data.microsoft_auth
        window.removeEventListener('message', login_handler)
    }
    // Retrieve token data to update the store.
    if ("token" in auth_data) {
        // Store token in the store.
        store.dispatch('set_auth_token', auth_data)
    }
    else if ("error" in auth_data) {
        window.alert(auth_data.error)
    }
}


// Resets the store to default state and clears auth data.
function logout() {
    django_api.get("core/logout/").then(() => {
        // Clear Store
        store.dispatch("logout")
    })
}


// Retrieves and stores permissions for the current user.
async function fetch_user_permissions() {
    // Retrieve user permissions from the API.
    let response
    try {
      // We need to wait for this return before
      response = await django_api.get("core/user/")
    }
    // In the event that the user doesn't exist, the call will error, and we
    // want to reset the login screen.
    catch(error) {
      logout()
      return false
    }
    // If we successfully get the details, we add them to the store and return.
    store.dispatch("set_user", response.data)
    return true
}


export {
    fetch_user_permissions,
    login,
    logout
}