<!-- Date field with an openable calendar menu -->
<template>
  <v-menu
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <core-text-field
        v-model="display"
        :label="label"
        :rules="rules"
        :dense="dense"
        :filled="!disabled"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"></core-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="selectDate()"> </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "core-datepicker",
  props: {
    value: {},
    rules: {},
    label: String,
    dense: Boolean,
    disabled: Boolean,
  },
  components: {},
  data() {
    return {
      date: null,
      menu: false,
    }
  },
  computed: {
    display() {
      return this.formatDate(this.date)
    },
  },
  methods: {
    selectDate() {
      this.menu = false
      this.$emit("input", this.date)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year}`
    },
  },
  mounted() {
    if (this.value) {
      this.date = this.value
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val != this.date) {
          this.date = val
        }
      },
    },
  },
}
</script>
