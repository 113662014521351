import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueMeta from "vue-meta";
import App from './App.vue'
import store from './javascript/store'
import router from './javascript/router'
import django_api from './javascript/django_api'
import * as env from './javascript/env_settings'


// Auto import our custom core components
const components = require.context('@/components/core', true, /\/\w+\.vue$/)
components.keys().forEach(el => {
  const name = String(components(el).default.name)
  Vue.component(name, components(el).default)
})

// Config Vue
Vue.prototype.$django_api = django_api
Vue.config.productionTip = false
Vue.use(VueMeta);

new Vue({
  render: h => h(App),
  django_api,
  router: router,
  store,
  vuetify
}).$mount('#app')
