<template>
  <v-autocomplete outlined v-bind="$attrs" v-on="$listeners">
    <!-- Slots -->
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "core-autocomplete",
  props: {},
  components: {},
}
</script>
