import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'


Vue.use(Vuetify)

// primary: UA Red
// secondary: Arizona Blue (dark)
// accent: Bloom
// secondary_md_accent: Blue (medium)
// secondary_accent: Blue (light)
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#441622", // https://www.color-hex.com/color/ab0520
        secondary: "#344B46", // https://www.color-hex.com/color/0c234b
        accent: "#EF4056",
        secondary_md_accent: "#3C4E6E",
        secondary_accent: "#378DBD",
        cool_gray: "#E2E9EB",
        success: "#3c4e6e",
        warning: "#546581",
        info: "#546581",
        error: "#B31D36",
        good: "#47192C",
        bad: "#AB0520",
        neutral: "#444",
      }
    },
    icons: {
      iconfont: 'mdi',
    }
  }
})
