import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import Cookies from "js-cookie"
import "es6-promise/auto"
import { OrderStatus } from "./constants"

Vue.use(Vuex)

// Store default state for clearing auth details.
const getDefaultState = function () {
  return {
    allUsers: [],
    allLanguages: [],
    allLexemes: [],
    allCategories: [],
    srsNewWordList: [],
    srsRecallList: [],
    srsProductionList: [],
    srsPlayList: [],
    highlightedText: "",
    alert: {},
    navCollapsed: false,
    lastRefreshed: null,
    // Stored as cookies
    dataLoading: false,
    auth_token: null,
    user: {},
  }
}


export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "vuex",
      paths: ["auth_token", "user"], // These define which elements are allowed to become cookies
      storage: {
        getItem: function (key) {
          return Cookies.get(key)
        },
        setItem: function (key, value) {
          Cookies.set(key, value, { expires: 365, secure: true }) // 365 days
        },
        removeItem: function (key) {
          Cookies.remove(key)
        },
      },
    }),
  ],
  state: getDefaultState(),
  getters: {  },
  actions: {
    logout: function (context) {
      context.commit("logout")
    },
    set_auth_token: function (context, payload) {
      context.commit("set_auth_token", payload)
    },
    set_persisted_state: function (context, payload) {
      context.commit("set_persisted_state", payload)
    },
    set_user: function (context, payload) {
      context.commit("set_user", payload)
    },
    set_alert: function (context, payload) {
      context.state.alert = payload
    },
  },
  mutations: {
    logout: function (state) {
      Object.assign(state, getDefaultState())
    },
    set_auth_token: function (state, payload) {
      state.auth_token = payload
    },
    set_persisted_state: function (state, payload) {
      Object.assign(state, payload)
    },
    set_user: function (state, payload) {
      state.user = payload
    },
    app_notifications: function (state, payload) {
      state.user.app_notifications = payload
    },
    email_notifications: function (state, payload) {
      state.user.email_notifications = payload
    },
  },
})
