<template>
  <v-toolbar class="ml-3 elevation-1" :height="$vuetify.breakpoint.xl ? '72px' : '60px'">
    <v-row class="align-center" no-gutters>
      <v-col :cols="!auth_token ? '3' : 'auto'">
        <v-tabs
          v-if="auth_token && $vuetify.breakpoint.lgAndUp"
          :value="selectedTab"
          :height="$vuetify.breakpoint.xl ? '72px' : '60px'"
          icons-and-text>
          <v-tabs-slider color="primary"> </v-tabs-slider>
          <v-tab v-for="app in apps" :key="app.title" @click="selectApp(app)">
            {{ app.title }}
            <v-icon small>{{ app.icon }}</v-icon>
          </v-tab>
        </v-tabs>
        <v-btn
          v-else-if="!$vuetify.breakpoint.lgAndUp"
          icon
          @click="$emit('navClicked')"
          class="pa-0 ma-0">
          <v-icon color="secondary" class="pa-0">mdi-menu</v-icon>
        </v-btn>
        <v-list-item v-else class="d-flex justify-start">
          <v-list-item-icon class="pl-0 ml-0">
            <v-img
              :src="require('@/assets/images/starfish.png')"
              max-height="40"
              max-width="40"></v-img>
          </v-list-item-icon>
        </v-list-item>
      </v-col>
      <!-- Header -->
      <v-spacer></v-spacer>
      <v-col v-if="$vuetify.breakpoint.lg" cols="auto">
        <h1>
          <span
            class="primary--text d-flex justify-start"
            :class="[$vuetify.breakpoint.lgAndDown ? 'smallerText' : '']">
            {{ pageDisplayInHeader }}
          </span>
        </h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-row class="justify-end align-center mr-2" style="flex-wrap: nowrap">
          <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="auto">
            <v-progress-circular
              v-if="dataLoading"
              color="primary"
              :size="20"
              :width="2"
              indeterminate></v-progress-circular>
          </v-col>
          <!-- <v-col cols="auto" v-if="auth_token">
            <v-btn icon @click="$emit('settingsClicked')" class="pa-0 ma-0">
              <v-icon color="secondary" class="pa-0">mdi-menu</v-icon>
            </v-btn>
          </v-col> -->
          <!-- Login Button. -->
          <v-col cols="auto" class="d-flex justify-end">
            <core-btn-primary inverted @click="login()" v-if="!auth_token"
              >Login<v-icon small class="ml-1">mdi-login</v-icon></core-btn-primary
            >
            <core-btn-primary inverted @click="logout()" v-else
              >Logout
              <v-icon small class="ml-1">mdi-logout</v-icon>
            </core-btn-primary>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-toolbar>
</template>
<script>
import * as auth from "@/javascript/auth"
import * as functions from "@/javascript/functions"
import * as router from "@/javascript/router"
import * as env from "../javascript/env_settings"

export default {
  name: "app_header",
  props: {
    notificationCount: Number,
    selectedApplication: String,
    pageDisplayInHeader: String,
  },
  data: function () {
    return {
      app: {},
      apps: {},
    }
  },
  computed: {
    auth_token: function () {
      return this.$store.state.auth_token
    },
    user: function () {
      return this.$store.state.user
    },
    selectedTab() {
      for (let i = 0; i < this.apps.length; i++) {
        if (this.apps[i].title == this.selectedApplication) return i
      }
      return 0
    },
    dataLoading() {
      return this.$store.state.dataLoading
    },
  },
  methods: {
    getEnv() {
      if (this.environment == "localhost") {
        return "blue"
      } else if (this.environment == "dev") {
        return "error"
      } else if (this.environment == "staging") {
        return "error"
      }
    },
    loadNavApps() {
      this.apps = router.getAuthorizedApps(this.user)
    },
    login() {
      auth.login()
    },
    logout() {
      auth.logout()
      // navigate home.
      this.$router.replace("/")
    },
    selectApp(app) {
      this.$emit("update:selectedApplication", app.title)

      let appLowercase = functions.nameToSlug(app.title)

      if (appLowercase == "home") {
        this.$router.push("/")
      } else {
        this.$router.push({
          name: "App Page",
          params: { app_slug: appLowercase, page_slug: app.home },
        })
      }
    },
  },
  watch: {},
  mounted: function () {},
}
</script>
