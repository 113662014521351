import axios from 'axios'
import router from './router'
import store from './store'
import * as auth from './auth'
import * as env from './env_settings'
import * as functions from "./functions"

let api_url = "http://localhost:8000/api/"
if(env.ENV_SETTINGS === "prod") {
    api_url = "https://starfish.michaeluebele.net/api/"
}

// Default axios instance with our API settings.
const instance = axios.create({
    baseURL: api_url,
    timeout: 60000
})


function header_generator(config) {
    let headers = {"Content-Type": "application/json"}

    if (config.data instanceof FormData){
        headers = {"Content-Type": "multipart/form-data"}
    }

    // If there is a token value in the store, update the headers.
    if(store.state.auth_token) {
        headers["Authorization"] = "Token " + store.state.auth_token
    }
    // Return modified headers.
    config.headers = headers
    return config
}


// Default response handler.
const response_handler = function(response) {
    return response
}


// Handles expected errors and rejects the promise for others.
const response_error_handler = function(error) {
    let response = error.response

    // Connection timed out
    if (error.code == "ECONNABORTED"){
        let alert = {
            type: "error",
            message: "The server timed out. The server may be experiencing slow connection. Please try again or email muebeletech@gmail.com if the problem persists."
        }

        store.dispatch("set_alert", alert)
    }
    else if (response){

        if(response.status == 500) { // Error NOT caught on server
            let alert
            if(env.ENV_SETTINGS === "dev" || env.ENV_SETTINGS === "localhost"){
                alert = {
                    type: "error",
                    message: functions.getErrorMessage(response.data)
                }
            } else {
              alert = {
                    type: "error",
                    message: "The server has encountered an unexpected error. If the problem persists,"
                    + " please send an email to muebeletech@gmail.com."
                }
            }

            store.dispatch("set_alert", alert)
        }
        else if (response.status == 404 || response.status == 400) { // Error caught on server
            let msg = (response.data.message || response.data)
            let alert = {
                type: "error",
                message: msg
            }

            store.dispatch("set_alert", alert)
        }
        else if(response.status == 401) { // Request not authorized
            // Get error detail message
            let msg = (response.data.detail || response.data)

            // Reauthenticate if token has expired.
            if(msg === "Token has expired" || msg === "Invalid token") {
                // Clear previous to update authorization headers.
                store.dispatch("logout")
                //  Initialize login flow.
                auth.login()

                return Promise.reject(error)
            }
            else{
                let alert = {
                    type: "error",
                    message: msg
                }

                store.dispatch("set_alert", alert)
            }
        }
    }
    else{
        return Promise.reject(error)
    }

    return Promise.resolve(error)
}

instance.interceptors.request.use(
    config => header_generator(config),
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => response_handler(response),
    error => response_error_handler(error),
)


export default instance