<template>
  <!-- <div v-if="dataLoading">
    <v-row class="justify-center align-center mt-12">
      <v-col cols="auto">
        <v-progress-circular
          v-if="dataLoading"
          color="primary"
          :size="200"
          :width="4"
          indeterminate>
          Data Loading...</v-progress-circular
        >
      </v-col>
    </v-row>
  </div> -->
  <div>
    <component :is="component" v-if="component" />
  </div>
</template>

<script>
import * as functions from "../../javascript/functions"

export default {
  props: ["app_slug", "page_slug"],
  data: function () {
    return {
      component: null,
    }
  },
  computed: {
    loader() {
      if (!this.app_slug || !this.page_slug) {
        return null
      }
      let folder = functions.slugToName(this.app_slug)

      return () => import(`../${folder}/${this.page_slug}`)
    },

    dataLoading() {
      return this.$store.state.dataLoading
    },
  },
  mounted() {
    this.component = () => this.loader()
  },
}
</script>
