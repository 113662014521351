<template>
  <v-select
    :filled="filled"
    outlined
    loader-height="4"
    persistent-hint
    dense
    style="border-radius: 15px"
    v-bind="$attrs"
    v-on="$listeners">
    <!-- Slots -->
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: "core-select-table",
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
}
</script>
