<!-- Props:
multiple: If True, allow multiple selection and activate the multiple chip slot
chipsDisplayed: Numbers of chips to display in select if multiple is set to true -->

<template>
  <v-select :multiple="multiple" outlined dense hide-details flat v-bind="$attrs" v-on="$listeners">
    <!-- Slots -->
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <!-- Multiple Chips slots -->
    <template v-if="multiple" v-slot:selection="{ item, index }">
      <v-chip v-if="index < chipsDisplayed" small outlined>
        <span>{{ item }}</span>
      </v-chip>
      <span v-if="index === chipsDisplayed" class="white--text text-caption ml-1 pl-1">
        (+ {{ $attrs.value.length - chipsDisplayed }} others)
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "core-select-table-header",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    chipsDisplayed: {
      type: Number,
      default: 1,
    },
  },
  components: {},
  mounted: function () {},
}
</script>
