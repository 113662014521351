<template>
  <v-text-field
    :outlined="outlined"
    :filled="filled"
    loader-height="4"
    persistent-hint
    dense
    v-bind="$attrs"
    v-on="$listeners">
    <!-- Slots -->
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "core-text-field-table",
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
}
</script>
