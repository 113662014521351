import { LanguageMap } from "./constants"
import axios from 'axios';

function nameToSlug(name){
    if (name){
        return name.toString().replace(/ /g, "_").toLowerCase()
    }
    return null
}

function slugToName(slug){
    if (slug){
        let words = slug.toString().split("_")

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(" ")
    }
    return null
}

function textToHtml(text){
    let copy = text.slice()

    /* eslint-disable no-useless-escape */ // <-- comment disables eslint. Is necessary for compilation

    // parse URLs
    let hyperlinkRegex =
    /https:[\w\.\-\%\:\/#\?=&]+/g

    let links = copy.matchAll(hyperlinkRegex)
    for (let link of links) {
    copy = copy.replace(link, `<a href=${link} target="_blank">${link}</a>`)
    }

    // parse new lines
    let newLineRegex = /(?:\r\n|\r|\n)/g
    copy = copy.replace(newLineRegex, '<br>')

    // parse user mentions (i.e. '@Michael Uebele')
    let atRegex = /@[A-Z][a-zA-Z\-]+ [A-Z][a-zA-Z\-]+/g
    let ats = copy.matchAll(atRegex)
    for (let at of ats) {
        copy = copy.replace(at, `<b><u>${at}</u></b>`)
    }

    return copy
}

function decimalNumber(input){
    let char = String.fromCharCode(input.keyCode);
    if (/^\d*\.?\d*$/.test(char)) return true;
    else input.preventDefault();
}

function toDatepickerTime(date){
    return date.toISOString().substr(0, 10)
}

function oneYearAgo(){
    let oneYearAgo = new Date(Date.now())
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    return oneYearAgo
}

function getErrorMessage(error){

    let bodyTagOpen = error.indexOf("<body>")
    let bodyTagClose = error.indexOf("</body>")

    let errorBody = error.substring(bodyTagOpen, bodyTagClose)

    let parser = new DOMParser()
    let doc = parser.parseFromString(errorBody, "text/html")

    let errorTitleElement = doc.getElementsByTagName("h1")
    let errorTitle = errorTitleElement["0"].innerHTML

    let errorValueElement = doc.getElementsByClassName("exception_value")
    let errorValue = errorValueElement["0"].innerHTML

    return 'The following error occured: ' + errorValue + ' ' + errorTitle
}

function validationPageJump(refs) {
    let invalidField = refs.$children.find((e) => {
        return !e.valid
    })

    if (invalidField) {
        invalidField.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
        })
        return true
    }

    return false
}

function urlCheck(url) {
    if (!url) {
      return true
    }

    const wwwStrip = /www\./
    if (wwwStrip.test(url)) {
        url = url.replace("www.", "")
    }

    /* eslint-disable-next-line no-useless-escape*/
    const regex = /^https:\/\/[a-z0-9-]+\.[a-z0-9-\/\._~:\?#\[\]@!\$&'\(\)\*\+,;%=]+$/i

    const valid = regex.test(url)
    if (!valid) {
      return "Invalid URL: match 'https://domain.extension' form"
    }

    return true
}



async function translate(word, language){

    const encodedParams = new URLSearchParams();
    encodedParams.set('source_language', LanguageMap[language]);
    encodedParams.set('target_language', LanguageMap["English"]);
    encodedParams.set('text', word);

    const options = {
        method: 'POST',
        url: 'https://text-translator2.p.rapidapi.com/translate',
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'X-RapidAPI-Key': '10389bc76fmsh28fdc3b135ab675p16b7abjsne238cd59709b',
            'X-RapidAPI-Host': 'text-translator2.p.rapidapi.com'
        },
        data: encodedParams,
    };

    try {
        const response = await axios.request(options);
        return response.data.data.translatedText
    } catch (error) {
        console.error(error);
    }

}

function getExampleSentences(word, language){

}

export {
    nameToSlug,
    slugToName,
    textToHtml,
    decimalNumber,
    toDatepickerTime,
    oneYearAgo,
    getErrorMessage,
    validationPageJump,
    urlCheck,
    translate
}