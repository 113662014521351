<template>
  <core-number-field
    loader-height="4"
    persistent-hint
    filled
    dense
    v-bind="$attrs"
    v-on="$listeners">
    <!-- Slots -->
  </core-number-field>
</template>

<script>
export default {
  name: "core-number-field-table",
  props: {},
  components: {},
}
</script>
