<template>
  <v-container fluid class="pa-2">
    <!-- Quick Links -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-toolbar class="primary darken-1 white--text">
          <v-spacer></v-spacer>
          <v-toolbar-title>Apps</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card width="900px" class="pa-4" elevation="4">
          <v-row>
            <v-col cols="4" v-for="(card, index) in cards" :key="card.title" class="py-8">
              <v-card width="200px" elevation="1" class="">
                <v-btn
                  :color="getColor(index)"
                  tile
                  block
                  x-large
                  outlined
                  height="100px"
                  @click="navigate(card.app, card.page)">
                  {{ card.title }}<v-icon class="ml-2">mdi-card-multiple</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as functions from "@/javascript/functions"
import * as router from "@/javascript/router"

export default {
  name: "landing_page",
  components: {},
  data: function () {
    return {
      cards: [
        {
          title: "SRS Flashcards",
          app: "flashcards",
          page: "learn",
        },
      ],
    }
  },
  methods: {
    getColor(index) {
      let colorA = "primary"
      let colorB = "secondary"
      let colors = [colorA, colorB]

      return colors[index % 2] // flip-flops button colors
    },
    navigate(app, pageName) {
      let pageSlug = functions.nameToSlug(pageName)
      let appSlug = functions.nameToSlug(app)

      this.$router.push({
        name: "App Page",
        params: { app_slug: appSlug, page_slug: pageSlug },
      })

      this.$emit("pageInHeaderDisplay", pageName)
    },
  },
}
</script>
