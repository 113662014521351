<template>
  <v-btn color="primary" :outlined="inverted" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "core-btn-primary",
  props: {
    inverted: Boolean,
  },
  components: {},
}
</script>
