<!-- Box for writing and submitting comments.
Props:
model - Class nname of the model being commented on? (i.e. "PurchaseRequest")
id - Id of the model being commented on (i.e. 1) -->
<template>
  <v-container class="mx-0 px-0">
    <v-row class="mx-1 px-0">
      <!-- Textarea -->
      <v-col cols="12" class="mx-0 px-0 pb-0 mt-1">
        <v-textarea
          ref="textarea"
          v-model="comment"
          outlined
          filled
          style="font-family: Times New Roman; font-size: 15px"
          v-bind="$attrs"
          v-on="$listeners"
          @keydown="key($event)"
          @input="input($event)">
        </v-textarea>
      </v-col>
      <!-- Menu -->
      <div
        :id="`commentBox${id}`"
        style="float: right; position: relative; width: 200px; z-index: 9999"
        :style="'left: ' + menuOffsetX + 'px; bottom: ' + menuOffsetY + 'px;'">
        <v-menu v-model="showUserMenu" rounded="lg" :attach="`#commentBox${id}`">
          <v-list class="py-0 my-0" style="background-color: #faf9f9f9" max-height="280">
            <v-list-item v-for="(user, index) in userTags" :key="index">
              <v-list-item-title>
                <core-user-chip :userId="user.id" @click="tagUser(user)"></core-user-chip>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <!-- Button -->
    <v-row class="d-flex justify-end">
      <v-col class="py-0 d-flex justify-end">
        <core-btn-primary :loading="isLoading" @click="writeComment()">Post Comment</core-btn-primary>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@500&family=Red+Hat+Mono&family=Xanh+Mono&display=swap");
</style>
<script>
import * as functions from "@/javascript/functions"

export default {
  name: "core-comment-box",
  props: ["model", "id"],
  components: {},
  data() {
    return {
      comment: "",
      isLoading: false,
      showUserMenu: false,
      atIndex: 0,
      charsTypedSinceAt: 0,
      userTagText: "",
      charWidth: 6.4, // manually calculate. Must update if font is changed.
      charIndex: 0,
      charsPerLine: 100, // auto-calculated
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    allUsers() {
      return this.$store.state.allUsers
    },
    userTags() {
      let users = this.allUsers
      if (this.userTagText != "") {
        users = users.filter((user) => {
          return user.full_name.toLowerCase().startsWith(this.userTagText)
        })
      }

      return users
    },

    menuOffsetX() {
      let lastNewLineIndex = this.comment.lastIndexOf("\n")
      let cursorX = this.charIndex - lastNewLineIndex // account for new lines
      while (cursorX > this.charsPerLine) cursorX -= this.charsPerLine // account for word wrap
      return 30 + this.charWidth * cursorX // estimate offset
    },
    menuOffsetY() {
      let lineCount = 0
      for (let i = 0; i < this.charIndex; i++) {
        if (this.comment[i] === "\n") {
          lineCount += 1 // account for new lines
        }
      }
      let cursorX = this.charIndex - this.comment.lastIndexOf("\n")
      lineCount += Math.floor(cursorX / this.charsPerLine) // account for word wrap
      return 180 - lineCount * 25 // estimate offset
    },
  },
  methods: {
    key(event) {
      // User pressed '@'
      if (event.key == "@") {
        this.showUserMenu = true
        this.charsTypedSinceAt = 0
        this.atIndex = event.target.selectionStart
      }
      // After pressing '@'
      else if (this.showUserMenu) {
        let escapeKeys = ["Escape"]
        let confirmKeys = ["Tab", "Enter"]
        // Escape
        if (escapeKeys.includes(event.key)) this.showUserMenu = false
        // Confirm
        else if (confirmKeys.includes(event.key)) {
          this.tagUser(this.userTags[0])
          event.preventDefault()
        }
        // Normal letter
        else if (event.key.length == 1) {
          this.charsTypedSinceAt += 1
        }
        // Backspace
        else if (event.key == "Backspace") {
          this.charsTypedSinceAt -= 1
        }
      }

      this.charIndex = event.target.selectionStart
      this.charsPerLine = (event.target.clientWidth - 30) / this.charWidth
    },
    input(event) {
      if (this.comment.indexOf("@") === -1) this.showUserMenu = false
      if (!this.showUserMenu) return

      this.userTagText = ""
      if (this.charsTypedSinceAt > 0) {
        let start = this.atIndex + 1
        let stop = start + this.charsTypedSinceAt
        this.userTagText = event.substring(start, stop).toLowerCase() // filter user tags shown on menu

        // Turn menu off if they just keep going.
        if (this.userTags.length == 0 && this.charsTypedSinceAt > 8) {
          this.showUserMenu = false
        }
      }
    },
    tagUser(user) {
      // formula: (start-@) + (selected name) + (@-end)
      if (user) {
        this.comment = this.comment.substring(0, this.atIndex + 1) + "{" + user.full_name + "} "
        this.comment.substring(this.charIndex + 1, this.comment.length)
      }

      this.showUserMenu = false
    },
    async writeComment() {
      if (this.comment === "") {
        return // Don't create a comment if there's no text.
      }
      this.isLoading = true

      await this.$django_api
        .post("core/comment/", {
          "text": this.comment,
          "model": this.model,
          "id": this.id,
        })
        .then((response) => {
          this.$emit("commentPosted", response.data)
          this.comment = ""
        })
      this.isLoading = false
    },
  },
}
</script>
