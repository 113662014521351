<template>
  <v-row class="pt-6">
    <v-sheet width="100%" height="">
      <div class="pa-2">
        <p class="text-h1">Uh Oh</p>
        <p class="text-h6">
          The page you've requested could not be found. If you feel that you've arrived at this page
          in an error, submit a ticket and the development team will respond as soon as possible.
        </p>
      </div>

      <v-row>
        <v-col cols="2">
          <v-btn color="primary" large @click="ret_home">Back To Home</v-btn>
        </v-col>
        <v-col cols="2">
          <div v-if="error">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" v-on="on" large class="ma-0"> Log </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 primary white--text" primary-title>
                  Here's what went wrong.
                </v-card-title>

                <v-card-text>
                  {{ error }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog = false" color="primary"> Close </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-row>
</template>

<script>
export default {
  props: ["error"],
  data: function () {
    return {
      dialog: false,
    }
  },
  methods: {
    ret_home: function () {
      this.$router.push(`/group/${this.$store.state.active_group}/home`)
    },
  },
}
</script>
