import not_authorized from './not_authorized'
import page_not_found from './page_not_found'
import server_error from './server_error'
import forbidden from './forbidden'

export default {
    not_authorized,
    page_not_found,
    server_error,
    forbidden
}