<template>
  <v-text-field :outlined="outlined" v-bind="$attrs" v-on="$listeners">
    <!-- Slots. These pass on slots from the parent on down to v-text-field child -->
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "core-text-field",
  props: {
    outlined: { type: Boolean, default: true },
  },
  components: {},
}
</script>
