<template>
  <v-overlay v-if="alert.message" :z-index="maxZIndex()">
    <v-alert
      v-click-outside="closeAlert"
      :type="alert.type"
      elevation="1"
      class="py-6 pr-8"
      min-width="400">
      <!-- Message -->
      <div style="max-height: 350px; overflow-y: scroll" class="mb-4">
        <v-row
          v-for="(line, index) in splitTextLines(alert.message)"
          :key="index"
          class="mx-1 my-1 align-center">
          {{ line }}
        </v-row>
      </div>
      <!-- Actions -->
      <v-row class="d-flex">
        <v-col class="d-flex justify-end">
          <v-btn outlined @click="closeAlert()">
            Close <v-icon right> mdi-close-thick </v-icon>
          </v-btn>
          <v-btn class="ml-4" v-if="showNavButton" outlined @click="navigate()">
            {{ navPage }} <v-icon right>mdi-arrow-right-bold-box-outline</v-icon>
          </v-btn>
          <v-btn class="ml-4" v-if="showConfirmDialog" outlined @click="confirm()">
            Confirm <v-icon right> mdi-check </v-icon>
          </v-btn>
          <!-- <v-btn class="ml-4" v-if="internalServerError" outlined @click="reportBug()">
            Report Bug <v-icon right> mdi-bug </v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
    </v-alert>
  </v-overlay>
</template>

<script>
import * as functions from "@/javascript/functions"

export default {
  name: "Alert",

  data: function () {
    return {}
  },
  computed: {
    alert() {
      return this.$store.state.alert
    },
    navPage() {
      if ("navPage" in this.alert) {
        return functions.slugToName(this.alert.navPage)
      }

      return ""
    },
    showNavButton() {
      return "navPage" in this.alert
    },
    showConfirmDialog() {
      return "askConfirmation" in this.alert
    },
    internalServerError() {
      return this.alert.message.startsWith("The server has encountered an unexpected error.")
    },
  },
  methods: {
    closeAlert() {
      this.$store.dispatch("set_alert", {})
    },
    confirm() {
      if ("function" in this.alert) {
        this.alert.function(this.alert.functionParam)
      }

      this.closeAlert()
    },
    splitTextLines(text) {
      return text.split("\n")
    },
    maxZIndex() {
      return Math.pow(2, 31) - 1 // 2147483647. Give Alert overlay maximum priority
    },
    navigate() {
      this.$router.push({
        name: "App Page",
        params: { app_slug: this.alert.navApp, page_slug: this.alert.navPage },
      })

      this.closeAlert()
    },
  },
}
</script>
